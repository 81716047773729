<template>
  <div>
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">{{ $t('msg.ONEX010T010.011') }}<!-- Total --> : <span class="num">{{ totalCount }}</span></span>
        <span class="input_box">
          <input type="text" id="filter" class="wid200" v-model="txtFilter" @keyup="changeFilter()" autocomplete="off" :placeholder="$t('msg.ONEX010T010.012')">
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid100" v-model="selPo">
            <option value="POL">Departure</option>
            <option value="POD">Arrival</option>
          </select>
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid200" v-model="selCtr" @change="checkSelectCtr()">
            <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="ctrInfo in ctrCdList" :key="ctrInfo.ctrCd" :value="ctrInfo.ctrCd">{{ ctrInfo.ctrEnm }}</option>
          </select>
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
      </div>
      <div id="realgrid" :style="`width: 100%; height: ${gridHeight}px`" />
    </div>

    <div class="flex_box mt10">
      <a class="button blue lg ml_auto mr5" href="#" @click.prevent="fnAction('TAX')">{{ $t('menu.MENU06.010') }}</a><!-- 정산/발행 -->
      <!-- <a class="button blue lg ml_auto mr5" href="#" @click.prevent="fnAction('INVOICE')">{{ $t('msg.ONGO080G010.019') }}</a> Invoice 발행/출력 -->
      <!-- <a class="button blue lg mr5" href="#" @click.prevent="fnAction('SETTLE')">{{ $t('msg.ONGO080G010.020') }}</a>운임수납 -->
      <!-- <a class="button blue lg mr5" href="#" @click.prevent="fnAction('TAX')">{{ $t('msg.ONGO080G010.021') }}</a> 계산서 발행 -->
      <!-- <a class="button blue lg mr5" href="#" @click.prevent="fnAction('FRT')">{{ $t('msg.ONGO080G010.022') }}</a>운임수정 요청 -->
      <!-- <a class="button blue lg" href="#" @click.prevent="fnAction('BL_ISSUE')">{{ $t('msg.ONGO080G010.023') }}</a> B/L 발행 -->
    </div>

    <common-unsolved ref="commonUnsolved" @callback="searchAct" />
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import exportSvc from '@/api/rest/trans/exportSvc'
import wueCommon from './WorkingUnsolvedExport.js'
import commons from '@/api/services/commons'

let gridView = GridView
let provider = LocalDataProvider

export default {
  name: 'WorkingUnsolvedExportIssue',
  components: {
    CommonUnsolved: () => import('@/pages/trans/CommonUnsolved')
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      gridHeight: 300,
      txtFilter: '',
      totalCount: 0,
      list: [],
      searchParams: {},
      tempData: {},
      colType: '01',
      prevTxtFilter: '',
      prevCheckedRows: [],
      prevGridSelect: undefined,
      ctrCdList: [],
      selCtr: '',
      selPo: 'POD',
      relYn: true //process.env.VUE_APP_MODE !== 'PRD' // JamSin
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this._ = require('lodash')
    this.lang = this.auth.serviceLang

    this.gridHeight = $('body').height() - 400
    if (this.gridHeight < 300) {
      this.gridHeight = 300
    }

    this.init()
  },
  mounted () {
    window.vmApp = this

    // const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)

    provider.setFields(wueCommon.fields)

    gridView.setColumns(wueCommon.columns2)
    gridView.setColumnLayout(wueCommon.layoutTyp02)
    gridView.setFooter({ visible: false })
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })

    gridView.header.height = 80
    gridView.displayOptions.rowHeight = 60
    gridView.displayOptions.fitStyle = 'evenFill'
    gridView.setCopyOptions({ enabled: true })

    this.gridEvents()
  },
  methods: {
    async init () {
      commons.getCommonsCodesCountry().then(res => {
        this.ctrCdList = res
      })

      if (this.$props.parentInfo.workType === 'E') {
        this.search(this.$props.parentInfo)
      }
    },
    search (searchParams) {
      this.searchParams = this._.cloneDeep(searchParams)
      this.searchAct()
    },
    searchAct () {
      exportSvc.getUnsolvedList(this.searchParams).then(res => {
        const list = res.data
        this.setGridData(list)
        this.tempData = {}
      })
    },
    setGridData (list) {
      if (list !== undefined) {
        list.forEach(vo => {
          vo.diffDayCnt = this.$ekmtcCommon.getDateDiffWithToday(vo.blPolEtd)
        })
      }
      this.totalCount = list === undefined ? 0 : list.length
      this.list = list

      const tempPrevData = sessionStorage.getItem('working-unsolved-prev-data')
      const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)

      if (prevData !== undefined && prevData.txtFilter !== '') {
        this.txtFilter = prevData.txtFilter
        this.changeFilter()
      } else if (this.prevTxtFilter !== '') {
        this.txtFilter = this.prevTxtFilter
        this.prevTxtFilter = ''
        this.changeFilter()
      } else {
        this.txtFilter = ''
        this.changeFilter()
        // provider.setRows(list)
      }

      if (prevData !== undefined) {
        if (prevData.checkedRows.length > 0) {
          gridView.checkItems(prevData.checkedRows, true)
          this.setRequestArea(gridView.getCheckedRows())
        }

        if (prevData.gridSelect !== undefined) {
          gridView.setCurrent(prevData.gridSelect)
        }
      } else if (this.prevCheckedRows.length > 0) {
        const chkRows = this.prevCheckedRows.filter(vo => {
          const tvo = list.find(v => v.blNo === vo.blNo)
          return tvo !== undefined
        }).map(vo => vo.idx)

        if (chkRows.length > 0) {
          gridView.checkItems(chkRows, true)
        }

        if (this.prevGridSelect !== undefined) {
          gridView.setCurrent(this.prevGridSelect)
        }
      }

      this.prevCheckedRows = []
      this.prevGridSelect = undefined
      sessionStorage.removeItem('working-unsolved-prev-data')
    },
    // filter
    changeFilter () {
      if (this.list === undefined || this.list.length === 0) {
        return
      }

      let arrFilter

      if (this.txtFilter.indexOf(',') !== -1) {
        arrFilter = this.txtFilter.split(',')
      } else {
        arrFilter = [this.txtFilter]
      }

      if (this.txtFilter === '') {
        this.gridList = this.list
      } else {
        this.gridList = this.list.filter(vo => {
          const bkgNo = vo.bkgNo.toLowerCase()
          const blNo = vo.blNo === undefined ? '' : vo.blNo.toLowerCase()
          const vslCd = vo.vslCd === undefined ? '' : vo.vslCd.toLowerCase()
          const voyNo = vo.voyNo === undefined ? '' : vo.voyNo.toLowerCase()
          const vslNm = vo.vslNm === undefined ? '' : vo.vslNm.toLowerCase()
          const por = vo.por === undefined ? '' : vo.por.toLowerCase()
          const dly = vo.dly === undefined ? '' : vo.dly.toLowerCase()
          const cntrQty = vo.cntrQty === undefined ? '' : vo.cntrQty.toLowerCase()
          const actShprCstEnm = vo.actShprCstEnm === undefined ? '' : vo.actShprCstEnm.toLowerCase()
          let txtFilter

          for (let i in arrFilter) {
            txtFilter = arrFilter[i].toLowerCase().replace(/^\s+|\s+$/g, '')
            if (txtFilter === '') {
              continue
            }
            if (bkgNo.indexOf(txtFilter) !== -1 || blNo.indexOf(txtFilter) !== -1) {
              return true
            } else if (vslCd.indexOf(txtFilter) !== -1 || voyNo.indexOf(txtFilter) !== -1 || vslNm.indexOf(txtFilter) !== -1) {
              return true
            } else if (por.indexOf(txtFilter) !== -1 || dly.indexOf(txtFilter) !== -1) {
              return true
            } else if (cntrQty.indexOf(txtFilter) !== -1) {
              return true
            } else if (actShprCstEnm.indexOf(txtFilter) !== -1) {
              return true
            }
          }
          return false
        })
      }
      this.totalCount = this.gridList.length
      provider.setRows(this.gridList)
    },
    gridEvents () {
      const TH = this

      gridView.onCellClicked = (grid, clickData) => {
        if (clickData.cellType === 'header' || clickData.cellType === 'head') {
          return
        }

        if (clickData.cellType === 'check') {
          const gridSelect = {
            itemIndex: clickData.itemIndex,
            dataRow: clickData.dataRow,
            fieldIndex: 0,
            column: 'bkgNo',
            fieldName: 'bkgNo'
          }
          grid.setCurrent(gridSelect)
        }
      }
    },
    fnIssue (e, bkgNo, blNo) {
      e.preventDefault()
      // B/L 발행
      const checkedRows = gridView.getCheckedRows()

      this.prevCheckedRows = []
      this.prevGridSelect = gridView.getCurrent()

      checkedRows.forEach(idx => {
        const blNo = this.gridList[idx].blNo || ''
        this.prevCheckedRows.push({ idx: idx, blNo: blNo })
      })

      this.$refs.commonUnsolved.openBlPaymentPop([blNo], 'O') // 수출: O, 수입: I
    },
    fnAction (type) {
      const checkedRows = gridView.getCheckedRows()

      if (checkedRows.length === 0) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.024') }) // 대상을 선택해 주세요.
        return
      } else if (checkedRows.length > 1 && type === 'FRT') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.031') }) // 대상을 1개만 선택해 주세요.
        return
      }

      // GA dataLayer 추가
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: this.gridList[checkedRows[0]].bkgNo,
        popup_bl_no: this.gridList[checkedRows[0]].blNo
      })

      this.prevTxtFilter = this.txtFilter
      this.prevCheckedRows = []
      this.prevGridSelect = gridView.getCurrent()
      const arrBlNo = []
      const arrIsFwdrYn = []
      checkedRows.forEach(idx => {
        const blNo = this.gridList[idx].blNo || ''
        const fwdrCstNo = this.gridList[idx].fwdrCstNo || ''
        const isFwdrYn = this.gridList[idx].isFwdrYn || ''
        arrBlNo.push(blNo)
        arrIsFwdrYn.push({ blNo: blNo, fwdrCstNo: fwdrCstNo, isFwdrYn: isFwdrYn, cstCd: this.memberDetail.cstCd, userCtrCd: this.memberDetail.userCtrCd })
        this.prevCheckedRows.push({ idx: idx, blNo: blNo })
      })

      if (type === 'INVOICE') {
        this.$refs.commonUnsolved.openInvoice(arrBlNo, 'O') // 수출: O, 수입: I
      } else if (type === 'SETTLE') { // 운임수납
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'FREIGHT', 'O') // 수출: O, 수입: I
      } else if (type === 'TAX') { // 계산서 발행
        // 정산 발행 화면으로 이동
        const searchParams = {
          eiCatCd: 'O',
          tab: '1',
          dtKnd: 'BL',
          asProfileYn: 'N',
          keyword: arrBlNo.join(',')
        }
        this.$router.push({ name: 'calcIssueMain', params: searchParams }).catch(() => {})
        //this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'TAX_INVOICE', 'O') // 수출: O, 수입: I
      } else if (type === 'FRT') { // 운임 수정 요청
        this.$refs.commonUnsolved.freightPopCall(arrBlNo[0])
      } else if (type === 'BL_ISSUE') {
        this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O', arrIsFwdrYn) // 수출: O, 수입: I
      } else {
        alert(type)
      }
    },
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('msg.PERS010L020.014')}_${this.$t('msg.ONEX010T010.002')}_${this.$t('msg.ONGO080G010.002')}` // 미해결업무_수출_미발행
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    },
    checkSelectCtr () {
      if (this.selCtr !== '') {
        if (this.selPo === 'POL') {
          console.log('POL' + this.selCtr)
          this.gridList = this.list.filter(vo => vo.polCtrCd === this.selCtr)
        } else if (this.selPo === 'POD') {
          console.log('POD' + this.selCtr)
          this.gridList = this.list.filter(vo => vo.podCtrCd === this.selCtr)
        }
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      } else {
        this.gridList = this.list
        this.totalCount = this.gridList.length
        provider.setRows(this.gridList)
      }
    }
  }
}
</script>
